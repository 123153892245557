import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import FeaturedResource from '../components/FeaturedResource';
import ResourcesList from '../components/ResourcesList';

import './index.css';

export class ArticlesPage extends Component {
  static propTypes = {
    articles: PropTypes.array.isRequired,
  };

  render() {
    const { articles } = this.props;

    const firstArticle = articles[0];

    return (
      <App>
        <div className="component articles-page">
          <Helmet title="Articles – ThinkReservations">
            <meta
              name="description"
              content="ThinkReservations is dedicated to providing engaging and informative educational content. Click here to read our articles!"
            />
          </Helmet>

          <FeaturedResource
            path={firstArticle.path}
            title={firstArticle.title}
            date={firstArticle.date}
            author={firstArticle.author}
            authorTitle={firstArticle.authorTitle}
            authorCompany={firstArticle.authorCompany}
            shortDescription={firstArticle.shortDescription}
            videoLength={firstArticle.videoLength}
            thumbnailImage={firstArticle.thumbnailImage}
          />

          <ResourcesList resources={articles} title="Recent articles" />
        </div>
      </App>
    );
  }
}

export default ArticlesPage;
