import React from 'react';

import ArticlesPage from '../../components/pages/resources/ArticlesPage';

export default (props) => {
  const { pageContext } = props;

  const { resources } = pageContext;

  return <ArticlesPage articles={resources} />;
};
